import * as React from "react";

import { CompanyPageComponent } from "../components/CompanyPageComponent/CompanyPageComponent";
import { Layout } from "../components/Layout/Layout";
import Seo from "../components/seo";

const IndexPage = () => (
    <Layout>
        <Seo title="Company" />
        <CompanyPageComponent />
    </Layout>
);

export default IndexPage;
