import styled from "styled-components";
import { StyledText } from "../IndexPageComponent/style";
import { titleColor } from "../../styles/variables";

export const StyledAboutWrapper = styled.div`
    padding: 95px 245px 70px;
`;

export const StyledLogoWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
`;

export const StyledAboutText = styled(StyledText)`
    text-align: center;
    color: ${titleColor};
    padding: 35px 105px 0px;
    font-size: 1.4rem;
`;
