import styled from "styled-components";
import { StyledText } from "../IndexPageComponent/style";
import { bgSecond } from "../../styles/variables";

export const StyledAboutTeamWrapper = styled.div`
    background-color: ${bgSecond};
    padding: 55px 130px;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
`;

export const StyledImgWrapper = styled.div`
    flex: 1 0 280px;
    margin-right: 110px;
`;

export const StyledAboutTeamInformation = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
`;

export const StyledAboutTeamText = styled(StyledText)`
    text-align: justify;
    margin-top: 45px;
    margin-bottom: 35px;
`;
