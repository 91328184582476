import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Star } from "../Star/Star";

import { StyledAchievmentsWrapper, StyledAchievmentsBoard, StyledAchievment } from "./style";

import { StyledAboutTeamInformation, StyledAboutTeamText, StyledImgWrapper } from "../AboutTeam/style";

import { StyledTitle, StyledText } from "../IndexPageComponent/style";

import img1 from "../../images/achievments/1.png";
import img2 from "../../images/achievments/2.png";
import img3 from "../../images/achievments/3.png";
import img4 from "../../images/achievments/4.png";

export const Achievments = () => {
    const achievments = [
        {
            img: img1,
            title: "",
            text: "Our first game we are so proud of. The game reached 100.000   downloads in App Store in first 3 days."
        },
        {
            img: img2,
            title: "",
            text: "The game reached 1-st plase in casual game list in May 2020. Proof of our solid vision and skills."
        },
        {
            img: img3,
            title: "",
            text: "The most popular game made by our team. More than 2 million gamers  all over the word play it now."
        },
        {
            img: img4,
            title: "",
            text: "New progect upcoming which will absorb all our vision and experience of more than 10 years of game design"
        }
    ];

    return (
        <>
            <StyledAchievmentsWrapper>
                <StyledImgWrapper>
                    <StaticImage src="../../images/achievments/3.png" alt="star" width={280} placeholder="blurred" />
                </StyledImgWrapper>
                <StyledAboutTeamInformation>
                    <StyledTitle>
                        <Star />
                        <h2>our achievments</h2>
                        <Star />
                    </StyledTitle>
                    <StyledAboutTeamText>
                        Our passion to game design and outstanding skills of our team allows us to create beautiful games millons peole play over the world
                    </StyledAboutTeamText>
                </StyledAboutTeamInformation>
            </StyledAchievmentsWrapper>
            <StyledAchievmentsBoard>
                {achievments.map((achievment, index) => (
                    <StyledAchievment key={index}>
                        <img src={achievment.img} alt={`item-${index}`} placeholder="blurred" />
                        <StyledText>{achievment.text}</StyledText>
                    </StyledAchievment>
                ))}
            </StyledAchievmentsBoard>
        </>
    );
};
