import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { About } from "../About/About";
import { AboutTeam } from "../AboutTeam/AboutTeam";
import { Achievments } from "../Achievments/Achievments";
import { Footer } from "../Footer/Footer";
import { Decorates } from "../Decorates/Decorates";

import { StyledBorder, StyledPageWrapper, StyledDecoratesWrapper, StyledHeaderWrapper } from "../IndexPageComponent/style";

const firstGameStyle = {
    position: "absolute",
    top: "-246px",
    left: "-600px",
    opacity: "0.4",
    transform: "rotate(57deg) rotateY(0deg)"
};

const secondGameStyle = {
    width: "740px",
    position: "absolute",
    top: "-60px",
    left: "968px",
    opacity: ".4",
    transform: "rotateY(180deg) rotate(-310deg)"
};

const spinningStyle = {
    position: "absolute",
    bottom: "545px",
    left: "527px"
};

const owlStyle = {
    position: "absolute",
    top: "714px",
    left: "20px",
    opacity: "0.4",
    transform: "rotateY(180deg)"
};

export const CompanyPageComponent = () => {
    return (
        <StyledPageWrapper>
            <StyledHeaderWrapper>
                <StaticImage src="../../images/company_header.png" width={300} placeholder="blurred" alt="company header" />
            </StyledHeaderWrapper>
            <StyledBorder>
                <StyledDecoratesWrapper>
                    <Decorates firstGameStyle={firstGameStyle} secondGameStyle={secondGameStyle} spinningStyle={spinningStyle} owlStyle={owlStyle} />
                    <About />
                    <AboutTeam />
                    <Achievments />
                    <Footer showButtons />
                </StyledDecoratesWrapper>
            </StyledBorder>
        </StyledPageWrapper>
    );
};
