import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { Star } from "../Star/Star";

import { StyledAboutTeamWrapper, StyledAboutTeamInformation, StyledImgWrapper, StyledAboutTeamText } from "./style";

import { StyledTitle } from "../IndexPageComponent/style";

export const AboutTeam = () => {
    return (
        <StyledAboutTeamWrapper>
            <StyledImgWrapper>
                <StaticImage src="../../images/two-business-partners.png" alt="star" width={280} placeholder="blurred" />
            </StyledImgWrapper>
            <StyledAboutTeamInformation>
                <StyledTitle>
                    <Star />
                    <h2>our team</h2>
                    <Star />
                </StyledTitle>
                <StyledAboutTeamText>
                    Our high professional team of more than 100+ employees is highly focused on quality and ready to face all challenges you meet developing sucessful products
                </StyledAboutTeamText>
                <Link to="/career">
                    <StaticImage src="../../images/join_btn.png" alt="star" width={200} placeholder="blurred" />
                </Link>
            </StyledAboutTeamInformation>
        </StyledAboutTeamWrapper>
    );
};
