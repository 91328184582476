import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { StyledAboutWrapper, StyledLogoWrapper, StyledAboutText } from "./style";

export const About = () => {
    return (
        <StyledAboutWrapper>
            <StyledLogoWrapper>
                <StaticImage src="../../images/star.png" alt="star" width={40} placeholder="blurred" />
                <StaticImage src="../../images/logo_may-play.png" alt="logo" width={450} placeholder="blurred" />
                <StaticImage src="../../images/star.png" alt="star" width={40} placeholder="blurred" />
            </StyledLogoWrapper>
            <StyledAboutText>We're a mobile game development company with the obsession to develop successful match-3 games for iOS, Android and other platforms.</StyledAboutText>
        </StyledAboutWrapper>
    );
};
