import styled from "styled-components";
import { bg } from "../../styles/variables";
import { StyledAboutTeamWrapper } from "../AboutTeam/style";
import { StyledText } from "../IndexPageComponent/style";

export const StyledAchievmentsWrapper = styled(StyledAboutTeamWrapper)`
    background-color: ${bg};
`;

export const StyledAchievmentsBoard = styled.div`
    padding: 55px 130px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: space-between;
    align-items: center;
`;

export const StyledAchievment = styled.div`
    flex: 0 1 40%;

    img {
        width: 140px;
    }

    ${StyledText} {
        text-align: justify;
        margin: 20px 0;
    }
`;
